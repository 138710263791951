.waybill-detail-dialog {
  .info-item {
    display: flex;

    span {
      font-size: 14px;
    }
  }

  .add-border {
    border-right: 0.5px solid rgb(67, 67, 67);
  }
}
