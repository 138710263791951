
@page {
  size: auto !important;
  margin: 0px!important;
  padding: 0px!important;
}


.container {
  width:87%;
  height:150px;
  display: flex;
  align-items: center;
  flex-direction: column;
 }

 
 .container table td {
  font-size: 37px;
  text-align: center;
 }

 
 .package {
   width: 100%;  
 }
 .container table {
  border-collapse: collapse;
 }
 
.container td {
   /* border: 0.2px solid rgb(4, 3, 3); */
   border-collapse: collapse;
   color: black !important;
   font-weight: bold;
 }
 
 .package .first {
   font-weight: bold;
   font-size: 37px;
  
 }

 .package .firstGreater {
  font-weight: bold;
  font-size: 60px;
  margin-right: 20px;
 
}

 
 .package .second {
   text-align: left;
   padding-left: 10px;
   font-weight: bold;
   color: black !important;
   
 }
 .package .third {
   color: red;
 }
 
