.preview-image {
  width: 200px;
  position: relative;

  img {
    width: 100%;
  }

  span {
    position: absolute;
    top: 4px;
    right: 3px;
    background: red;
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 14px;
    color: white;
    border-radius: 100%;
    cursor: pointer;
  }

  &.network {
    span {
      background: green;
    }
  }
}
