.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
}

.icon-container {
  i {
    font-size: 150px;
    color: 'lightGrey';
    margin-bottom: 8px;
  }
}

h1,
p {
  color: 'lightGrey';
}
