.Finance-Print {
  padding: 20px;

  .Container {
    width: 96%;
    display: flex;
    margin-top: 3px;
    justify-content: space-between;
  }

  .LeftContainer {
    width: 90%;
    padding: 0px 14px;
    padding-right: 40px;
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .ContainerHeader {
    margin-top: 3px;
    .Container {
      .LogoContainer {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        h1 {
          font-weight: bold;
          font-size: 24px;
        }
      }
    }
    .BarCodeContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      h2 {
        margin-right: 90px;
      }
    }
    .CodesContainer {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      margin-bottom: 15px;
      h2 {
        font-weight: bold;
      }
    }
  }

  .RightContainer {
    flex: 5;
    padding: 7px 12px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .Header {
    flex: 1;
  }

  .Item {
    flex: 1;
    margin-bottom: 12px;
  }

  .Container p,
  h4,
  h2,
  h3 {
    padding: 0;
    margin: 0;
  }

  .Container img {
    width: 150px;
    height: 80px;
  }

  .Container h4 {
    font-size: 18px;
  }

  .Container h3 {
    font-size: 19px;
  }

  .Container h2 {
    font-size: 25px;
  }

  .Container p {
    font-size: 17px;
  }

  .Divider {
    border-bottom: 1px solid black;
  }

  .CenterBarcode {
    display: flex;
    justify-content: center;
  }

  .Total {
    display: flex;
    justify-content: flex-end;
  }

  .Footer {
    display: flex;
    justify-content: center;
    margin-top: 2px;
  }

  .FooterContainer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

    .DividerRow{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0px 15px;
    }
  }
}
