.grid-background {
  background: #e9e9ef;
}

.button-container {
  display: flex;
  column-gap: 10px;
  .btn {
    margin: 0px;
  }
}

.pagination-container {
  width: 200px;
  overflow-x: auto;
}

@media only screen and (max-width: 800px) {
  .button-container {
    .btn {
      margin-bottom: 10px;
    }
  }
}
