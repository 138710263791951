.order-modal {
  label {
    font-size: 0.9rem;
  }

  .divider {
    width: 100%;
    height: 5px;
    border-bottom: 1px solid #c8c8c8;
    margin-bottom: 8px;
  }

  .custom-modal-footer {
    position: fixed;
    bottom: 0px;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    z-index: 2;
    left: 0;
  }
}
