.image-container {
  display: flex;
  padding: 8px 0px;
  flex-wrap: wrap;
  margin-bottom: 20px;

  img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    object-position: center;
  }
}

.file-thumbnail {
  background: #dfdfdf;
  margin-right: 8px;
  margin-bottom: 8px;
  position: relative;

  span {
    position: absolute;
    top: 4px;
    right: 3px;
    background: red;
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 14px;
    color: white;
    border-radius: 100%;
    cursor: pointer;
  }
}

.preview_container {
  position: fixed;
  flex-direction: column;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 300;
  /* justify-content: center; */
  align-items: flex-start;

  span {
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 52px;
    color: black;
  }
}
.preview_container img,
iframe {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
