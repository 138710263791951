.waybill-form {
  margin-top: 14px;
  .divider {
    width: 100%;
    height: 5px;
    border-bottom: 1px solid #c8c8c8;
    margin-bottom: 8px;
  }

  label {
    font-size: 0.9rem;
  }
}
