.container {
  height: 100%;
  width: 580px;
  display: flex;
  flex-direction: column;
  /* color: #000000; */
  text-align: center;
}

@media print {
  .container {
    height: 100%;
    width: 100%;
    text-align: center;
    /* color: #000000; */
  }
}
.package {
  width: 100%;
  table-layout: fixed;
}

.container table,
.container table th,
.container td {
  border: 0.2px solid rgb(43, 43, 43);
}

.package .first {
  font-weight: bold;
}
.package .second {
  display: flex;
  justify-content: left;
  padding-left: 10px;
  font-weight: 400;
}
.package .third {
  color: red;
}
