.profile-img-container {
    background-color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    width:240px;
    height: 200px;
    font-size: 2rem;
    cursor: pointer;
}

.profile-img-preview {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

.dropdown-menu {
    width: 100px;
  }