.parent {
  display: flex;

  padding: 0px 20px;
  align-items: center;

  button {
    font-size: 15px;
    margin-top: 10px;
  }
}
.tag-container {
  margin-top: 10px;
  display: flex;
  width: 93%;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-right: 10px;

  div {
    padding: 0px 10px;
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 0;
    border: 1px solid #e6e6e6;
    border-radius: 4px 4px 0 0;
    height: 30px;
    column-gap: 10px;
    background: white;
    box-shadow: -1px 1px #e6e6e6, -2px 2px #e6e6e6;
    justify-content: space-around;
    align-items: center;

    font-size: 12px;
    span {
      cursor: pointer;
    }

    &.active {
      background: #1a2753;
      color: white;
      span:last-of-type {
        display: none;
      }
    }
  }
}

.tag-container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
