.main-bg {
  background-image: linear-gradient(#6a6a6a, #233647);
}

.content-card {
  width: 80%;
}

.image-to {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .why-use {
    padding-top: 60px;
  }

  .content-card {
    width: 100%;
  }

  .form-container {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 800px) {
  .left-img {
    display: none;
  }
}
