.PrintPage {
  padding: 10px;
  padding-left:30px;
  padding-top:20px;
  width: 210mm;
  margin: 0px;
}

.LogoDiv {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-items: flex-end;
}

.Logo {
  display: flex;
  align-items: center;
}

.Logo span {
  font-size: 32px;
  font-weight: bold;
}

.PrintHead {
  text-align: center;
  font-size: 32px;
}

.PrintPage p {
  text-align: right;
  font-size: 22px;
  padding: 0px;
  margin: 0px;
}

.PrintDataWrapper {
  border: 1px solid #000;
  padding: 8px;
  margin: 5px 0px;
}

.PrintData {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}

.PrintData span {
  font-size: 22px;
}

.ReceivedPersonWrapper {
  text-align: left;
  font-size: 22px;
  margin-bottom: 8px;
}

.BranchWrapper {
  text-align: center;
  font-size: 22px;
  margin-top: 8px;
}
